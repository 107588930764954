@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif; /* Change to Open Sans */
}

body, html {
  overflow-x: hidden;
  scroll-behavior: smooth;
  position: relative;
  font-family: 'Open Sans', sans-serif; /* Change to Open Sans */
}

a {
  text-decoration: none;
  color: inherit !important;
}

::selection {
  background-color: var(--primary-color--);
  color: #fff;
}
